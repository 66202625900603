import styled from 'styled-components';
import { GrayWrapper } from '../../../components/layouts/layout/layout';

export const StyledHeading = styled.h2`
  ${({ theme }) => theme.typography.fontSize.xl}
  ${({ theme }) => theme.typography.fontWeight.regular}
`;

export const StyledFamilyTypeSection = styled(GrayWrapper)`
  padding: 60px 0 170px;
`;

export const StyledFamilyTypeList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.typography.fontSize.m}
  margin: 10px 0;

  ${({ theme }) => theme.breakpoints.desktop} {
    gap: 24px;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    gap: 12px;
  }
`;
