import { LastSearchService } from './last-searches.interface';
import { SaveLastSearch } from '@y2/api-clients/price-list';

export const SEARCHES_LIMIT_COUNT = 4;

const STORAGE_KEY = 'pl-ls';

const storageSet = (data: SaveLastSearch[]) =>
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));

// eslint-disable-next-line require-await
export const get: LastSearchService['get'] = async () => {
  try {
    const itemsStr = localStorage.getItem(STORAGE_KEY);
    return itemsStr ? JSON.parse(itemsStr) : [];
    // eslint-disable-next-line custom-rules/enforce-catch-err-handling
  } catch (error) {
    return [];
  }
};

const append: LastSearchService['append'] = async ([lastSearch]) => {
  const items = await get();
  items.unshift({ ...lastSearch, createdAt: Date.now() });
  storageSet(items.slice(0, SEARCHES_LIMIT_COUNT));
};

const remove: LastSearchService['remove'] = async (index) => {
  const items = await get();
  items.splice(index, 1);
  storageSet(items);
};

export const clearLocalStorage = () => {
  storageSet([]);
};
export const local: LastSearchService = {
  get,
  remove,
  append,
};
