import { toLocalNis } from '../../utils';
import styled from 'styled-components';

type Props = {
  price: number;
  className?: string;
};

export function PriceNisDisplay({ price, className }: Props) {
  return (
    <PriceNisDisplayBox className={className} data-testid="price-nis-display">
      {toLocalNis(price)}
    </PriceNisDisplayBox>
  );
}

const PriceNisDisplayBox = styled.span`
  direction: ltr;
  unicode-bidi: embed;
`;
