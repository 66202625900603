import styled from 'styled-components';
import texts from './disclaimer-content.texts.json';

export const DisclaimerContent = () => (
  <Content>{texts.disclaimerText}</Content>
);

export const Content = styled.p`
  ${({ theme }) => theme.typography.fontSize.xs};
  ${({ theme }) => theme.typography.fontWeight.regular};
  line-height: 20px;
  padding: 1.6rem 1.5rem 1.2rem 2.1rem;

  ${({ theme }) => theme.breakpoints.desktop} {
    min-width: 375px;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    min-width: 334px;
  }
`;
