import { LastSearchResponse as LastSearch } from '@y2/api-clients/price-list';
import { HistoryIcon, CloseBoldIcon } from '@y2/deprecated/ui/icons';
import styled from 'styled-components';
import Link from 'next/link';

type Props = {
  className?: string;
  onDelete: () => void;
  lastSearch: LastSearch;
};

export function LastSearchesItem({
  className,
  lastSearch: { href, title, searchDescription },
  onDelete,
}: Props) {
  return (
    <LastSearchesItemBox>
      <Link href={href}>
        <LastSearchesItemContent className={className}>
          <IconBox>
            <HistoryIcon />
          </IconBox>
          <ContentBox>
            <strong title={title}>{title}</strong>
            <span title={searchDescription}>{searchDescription}</span>
          </ContentBox>
        </LastSearchesItemContent>
      </Link>
      <DeleteButton onClick={onDelete}>
        <CloseBoldIcon />
      </DeleteButton>
    </LastSearchesItemBox>
  );
}

const LastSearchesItemBox = styled.div`
  position: relative;
`;
const LastSearchesItemContent = styled.div`
  width: 275px;
  height: 75px;
  padding: 12px;
  display: flex;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.common.white};
  ${({ theme }) => theme.shadows.level[0]}
`;

const iconBackGround = '#F4FAFB';
const iconBackFill = '#8DCED6';

const IconBox = styled.div`
  width: 50px;
  height: 50px;
  line-height: 50px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${iconBackGround};
  text-align: center;
  margin-left: 10px;

  > svg {
    font-size: 32px;

    path {
      fill: ${iconBackFill};
    }
  }
`;

const ContentBox = styled.div`
  width: 200px;
  ${({ theme }) => theme.typography.fontSize.xs}
  padding: 4px;

  > * {
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > strong {
    padding-left: 12px;
    margin-bottom: 6px;
    ${({ theme }) => theme.typography.fontWeight.regular}
  }

  > span {
    color: ${({ theme }) => theme.palette.common.spanishGrey};
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  font-size: 10px;
  top: 0;
  left: 0;
  padding: 12px;
`;
