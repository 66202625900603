import styled from 'styled-components';

export const StyledCoverNavHeading = styled.strong`
  ${({ theme }) => theme.typography.fontWeight.medium}
  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ theme }) => theme.typography.fontSize.l}
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    ${({ theme }) => theme.typography.fontSize.xxxl}
  }
`;

export const StyledCoverNav = styled.div`
  position: relative;
  z-index: 2;
  overflow: visible;

  ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 50px;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 30px;
  }
`;

export const StyledNav = styled.div`
  display: flex;
  margin-top: 24px;
  ${({ theme }) => theme.breakpoints.mobile} {
    justify-content: center;
    ${({ theme }) => theme.typography.fontSize.xs};
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    justify-content: start;
    ${({ theme }) => theme.typography.fontSize.xl};
  }
`;
