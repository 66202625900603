/* istanbul ignore file */
import { LastSearchService } from './last-searches.interface';
import { clearLocalStorage, get as localGet } from './local';
import {
  getUserLastSearches,
  saveLastSearches,
  deleteLastSearch,
} from '../../api-services';

const get: LastSearchService['get'] = async () => {
  try {
    return await getUserLastSearches();
    // eslint-disable-next-line custom-rules/enforce-catch-err-handling
  } catch (error) {
    return [];
  }
};

const append: LastSearchService['append'] = async (lastSearches) => {
  await saveLastSearches(
    lastSearches.map((lastSearch) => ({
      createdAt: Date.now(),
      ...lastSearch,
    })),
  );
};

const remove: LastSearchService['remove'] = async (index, lastSearches) => {
  if (lastSearches) {
    const { _id } = lastSearches[index];
    await deleteLastSearch(_id);
  }
};

export const syncLocalToRemote = async () => {
  const localLastSearches = await localGet();
  if (localLastSearches.length) {
    await append(localLastSearches);
    clearLocalStorage();
  }
};

export const remote: LastSearchService = {
  get,
  remove,
  append,
};
