import { CloseButton } from './close-button/close-button';
import { StyledTooltip, StyledBox } from './tooltip.styled';
import React, { type MouseEventHandler, useState, useRef } from 'react';
import { usePopupViewportPosition } from '@y2/hooks/use-popup-viewport-position';

type TooltipButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave: MouseEventHandler<HTMLButtonElement>;
};

export type TooltipProps = {
  TooltipButton: (props: TooltipButtonProps) => JSX.Element;
  content: React.ReactNode;
  className?: string;
};

const Tooltip = ({ className, content, TooltipButton }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleOnClick = () => setIsOpen(!isOpen);

  const handleOnMouserEnter = () => setIsOpen(true);

  const handleOnMouserLeave = () => setIsOpen(false);

  usePopupViewportPosition(ref, isOpen, true);

  return (
    <StyledBox className={className}>
      <TooltipButton
        onClick={handleOnClick}
        onMouseEnter={handleOnMouserEnter}
        onMouseLeave={handleOnMouserLeave}
      />

      {isOpen && (
        <StyledTooltip ref={ref} role="tooltip">
          <CloseButton onClick={handleOnClick} />
          {content}
        </StyledTooltip>
      )}
    </StyledBox>
  );
};

export default Tooltip;
