import styled, { css } from 'styled-components';
import { Container } from '../../../components/layouts/layout/layout';

export interface CollapsibleProps {
  isCollapsed: boolean;
}

export const StyledPopularManufacturersSectionWrapper = styled.section<CollapsibleProps>`
  margin-bottom: 55px;

  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ isCollapsed }) =>
      !isCollapsed &&
      css`
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        background-color: ${({ theme }) => theme.palette.common.snow};
        overflow: auto;
        margin-bottom: 0;
      `}
  }
`;

export const HeadingBox = styled.div<CollapsibleProps>`
  ${({ theme }) => theme.typography.fontWeight.regular}
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 20px;
  }

  > span {
    display: none;

    ${({ theme }) => theme.breakpoints.mobile} {
      ${({ isCollapsed }) => !isCollapsed && 'display: inline;'}
    }
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    position: relative;

    ${({ isCollapsed }) =>
      !isCollapsed &&
      css`
        line-height: 58px;
        justify-content: center;
      `}
  }
`;

export const Heading = styled.h2`
  ${({ theme }) => theme.typography.fontWeight.regular}
  ${({ theme }) => theme.breakpoints.desktop} {
    ${({ theme }) => theme.typography.fontSize.xxxl}
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ theme }) => theme.typography.fontSize.m}
  }
`;

export const HeadingContainer = styled(Container)<CollapsibleProps>`
  ${({ theme }) => theme.breakpoints.desktop} {
      padding-top: 40px;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ isCollapsed, theme }) => !isCollapsed && theme.shadows.level[1]}
`;

export const StyledContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.desktop} {
    overflow: hidden;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    overflow: visible;
  }
`;
