import {
  StyledPopularManufacturersSectionWrapper,
  HeadingContainer,
  StyledContainer,
  HeadingBox,
  Heading,
} from './popular-manufacturers-section.styled';
import PopularManufacturers from './popular-manufacturers/popular-manufacturers';
import { pushShowMoreManufacturersEvent } from '../../../services/events/home';
import ShowMoreButton from './show-more-button/show-more-button';
import Texts from './popular-manufacturers-section.text.json';
import { Manufacturer } from '@y2/api-clients/price-list';
import React from 'react';

type Props = {
  manufacturers: Manufacturer[];
};

export function PopularManufacturersSection({ manufacturers }: Props) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const toggleCollapsed = () => {
    if (isCollapsed) pushShowMoreManufacturersEvent();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <StyledPopularManufacturersSectionWrapper isCollapsed={isCollapsed}>
      <HeadingContainer isCollapsed={isCollapsed}>
        <HeadingBox isCollapsed={isCollapsed}>
          <Heading>
            <span>{Texts.allThe}</span>
            {Texts.sectionHeading}
          </Heading>
          <ShowMoreButton isCollapsed={isCollapsed} onClick={toggleCollapsed} />
        </HeadingBox>
      </HeadingContainer>
      <StyledContainer>
        <PopularManufacturers
          isCollapsed={isCollapsed}
          manufacturers={manufacturers}
        />
      </StyledContainer>
    </StyledPopularManufacturersSectionWrapper>
  );
}

export default PopularManufacturersSection;
