import { analytics } from '@y2/google-tag-manager';
import { environment } from '../../environments/environment';
export const pushHomePageEvent = (pageVirtualURL: string, userId?: number) =>
  analytics.pushPageEvent({
    PageType: 'Price list homepage',
    CatID: 'null',
    SubCatID: 'null',
    PageVirtualURL: pageVirtualURL,
    environment: environment.env,
    UserID: userId?.toString() || '',
    ClientStatus: userId ? 'loggedin' : 'unknown',
  });

export const pushShowMoreManufacturersEvent = () =>
  analytics.pushEvent({
    GA_event_category: 'price list',
    GA_event_action: 'homepage interactions',
    GA_event_label: 'show more clicked',
    GA_event_label_value: 'manufacturers',
  });
