import styled from 'styled-components';

export const StyledPopularManufacturersItem = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.fontWeight.regular}
  padding: 6px;
  width: 92px;
  height: 92px;
`;

export const StyledImageBox = styled.div`
  margin: 0 6px 6px;
  min-width: 55px;
`;
