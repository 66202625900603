import PopularManufacturersSection from '../features/home-page/popular-manufacturers-section/popular-manufacturers-section';
import PopularModelsSection from '../features/home-page/popular-models-section/popular-models-section';
import FamilyTypeSection from '../features/home-page/family-type-section/family-type-section';
import WhyUsSection from '../features/home-page/why-us-section/why-us-section';
import {
  HomePageResults,
  LastSearchResponse,
} from '@y2/api-clients/price-list';
import { syncLocalToRemote } from '../services/last-searches/remote';
import { getLastSearchesService } from '../services/last-searches';
import { getHomePageData } from '../api-services';
import Cover from '../components/cover/cover';
import { PagePropsExpansion } from './_app';
import { GetServerSideProps } from 'next';
import { useAsyncEffect } from 'ahooks';
import Texts from './index.text.json';
import Head from 'next/head';
import { useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { CROSS_DOMAIN } from '../services/common/consts';
import { pushHomePageEvent } from '../services/events/home';
import { LastSearches } from '../features/home-page/last-searches/last-searches';
import { LastSearchService } from '../services/last-searches/last-searches.interface';

type PageProps = HomePageResults['data'] & PagePropsExpansion;

export function Index({
  manufacturers,
  models,
  setFooterText,
  user,
  isLoading,
}: PageProps) {
  const router = useRouter();
  const [lastSearches, setLastSearches] = useState<LastSearchResponse[]>([]);
  const lastSearchesService = useRef<null | LastSearchService>(null);

  useAsyncEffect(async () => {
    if (!isLoading && typeof user !== 'undefined') {
      pushHomePageEvent(window.location.href, user?.id);
      lastSearchesService.current = getLastSearchesService(!user?.id);
      // eslint-disable-next-line custom-rules/no-nested-if-conditions
      if (user?.id) await syncLocalToRemote();
      setLastSearches(await lastSearchesService.current.get());
    }
  }, [user, isLoading]);
  const handelDeleteItem = async (itemIndex: number) => {
    await lastSearchesService.current?.remove(itemIndex, lastSearches);
    setLastSearches((await lastSearchesService.current?.get()) || lastSearches);
  };
  setFooterText(Texts.footerText);
  return (
    <>
      <Head>
        <title>{Texts.metaTitle}</title>
        <meta name="robots" content="FOLLOW,INDEX" />
        <meta name="description" content={Texts.metaDescription} />
        <meta name="og:title" content={Texts.ogTitle} />
        <meta name="og:description" content={Texts.ogDescription} />
        <link rel="canonical" href={`${CROSS_DOMAIN}${router.basePath}`} />
      </Head>
      <div className="home-page">
        <Cover />
        <LastSearches
          lastSearches={lastSearches}
          onDeleteByIndex={handelDeleteItem}
        />
        <PopularManufacturersSection manufacturers={manufacturers} />
        <WhyUsSection />
        <PopularModelsSection models={models} />
        <FamilyTypeSection />
      </div>
    </>
  );
}

export default Index;

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const { data } = await getHomePageData();
  return {
    props: { ...data },
  };
};
