import { manufacturerImgBasePath } from '../../../../../services/assets/assets';
import {
  StyledPopularManufacturersItem,
  StyledImageBox,
} from './popular-manufacturers-item.styled';
import Image from 'next/image';
import React from 'react';

type Props = {
  text: string;
  img: string;
};

function PopularManufacturersItem({ img, text }: Props) {
  return (
    <StyledPopularManufacturersItem>
      <StyledImageBox>
        <Image
          src={`${manufacturerImgBasePath}${img}?ver=v2`}
          alt={`Logo ${text}`}
          width={55}
          height={40}
          style={{ objectFit: 'contain' }}
        />
      </StyledImageBox>
      <h4>{text}</h4>
    </StyledPopularManufacturersItem>
  );
}

export default PopularManufacturersItem;
