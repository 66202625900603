import styled from 'styled-components';

const StyledCoverNavItem = styled.a`
  background-color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
  width: 8.6em;
  height: 8.6em;
  border-radius: 0.9em;
  box-shadow: 0 12px 58px 0 rgba(62, 63, 84, 0.1);
  overflow: visible;

  ${({ theme }) => theme.breakpoints.desktop} {
    &:hover {
      box-shadow: 0 12px 50px 0 rgba(62, 63, 84, 0.24);
    }
  }

  &:first-of-type {
    margin-left: 1.2em;
  }

  img,
  svg {
    display: block;
    margin: 0.25em auto;
    font-size: 4em;
  }

  img {
    width: 1em;
    height: 1em;
  }
`;

export default StyledCoverNavItem;
