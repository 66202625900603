import Tooltip from '../../components/tooltip/tooltip';
import { InfoButton } from './info-button/info-button';
import { DisclaimerContent } from './disclaimer-content/disclaimer-content';

type Props = { className?: string };

const PriceDisclaimer = ({ className }: Props) => (
  <Tooltip
    className={className}
    content={<DisclaimerContent />}
    TooltipButton={InfoButton}
  />
);

export default PriceDisclaimer;
