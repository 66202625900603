import {
  StyledCoverNavHeading,
  StyledCoverNav,
  StyledNav,
} from './cover-nav.styled';
import { getAssetPath } from '../../../services/assets/assets';
import CoverNavItem from './cover-nav-item/cover-nav-item';
import text from './cover-nav.text.json';

export function CoverNav() {
  return (
    <StyledCoverNav>
      <StyledCoverNavHeading>{text.coverNavHeading}</StyledCoverNavHeading>
      <StyledNav>
        <CoverNavItem
          href="/search/specific#manufacturer"
          text={text.byModelLink}
          eventLabel="specific model"
        >
          <img src={getAssetPath('search.svg')} alt={text.byModelLink} />
        </CoverNavItem>
        <CoverNavItem
          href="/search/general#family-type"
          text={text.carTypeLink}
          eventLabel="car type"
        >
          <img src={getAssetPath('binoculars.svg')} alt={text.carTypeLink} />
        </CoverNavItem>
      </StyledNav>
    </StyledCoverNav>
  );
}

export default CoverNav;
