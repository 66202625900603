import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    width: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    ${({ theme }) => theme.breakpoints.mobile} {
      display: none;
    }
  }
`;

export const StyledPopularModelsSwiper = styled.div`
  margin-bottom: 50px;

  ${({ theme }) => theme.breakpoints.mobile} {
    width: calc(100% + 15px);
  }
`;
