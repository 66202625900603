import styled from 'styled-components';
export const StyledBox = styled.div`
  ${({ theme }) => theme.breakpoints.desktop} {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    display: inline-block;
  }
`;

export const StyledTooltip = styled.div`
  position: absolute;
  min-width: fit-content;
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.pastelGrey};
  border-radius: 8px;
  ${({ theme }) => theme.shadows.level[2]};
  z-index: ${({ theme }) => theme.zIndex.floaters};

  ${({ theme }) => theme.breakpoints.desktop} {
    top: 100%;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    right: 0;
    margin-inline: 1rem;
  }
`;
