import {
  StyledPopularModelsSwiperSlide,
  StyledImagBox,
  StyledImage,
  StyledContentBox,
} from './popular-models-swiper-slide.styled';
import { PriceNisDisplay } from '../../../../../components/price-nis-display/price-nis-display';
import { modelImgBasePath } from '../../../../../services/assets/assets';
import Text from '../../popular-models-section.text.json';
import { Model } from '@y2/api-clients/price-list';
import Image from 'next/image';
import Link from 'next/link';
import { fields } from '../../../../../services/search/consts';

type Props = Model;

export function PopularModelsSwiperSlide({
  img,
  title,
  year,
  minPrice,
  id,
  manufacturer,
}: Props) {
  return (
    <StyledPopularModelsSwiperSlide>
      <Link
        href={`/feed?${fields.MANUFACTURER}=${manufacturer}&${fields.MODEL}=${id}`}
        passHref
        legacyBehavior
      >
        <a>
          <StyledImagBox>
            <StyledImage>
              <Image
                src={`${modelImgBasePath}${img}?ver=v2`}
                width={260}
                height={130}
                style={{ objectFit: 'contain' }}
                alt=""
              />
            </StyledImage>
          </StyledImagBox>
          <StyledContentBox>
            <h3>
              <strong>{title}</strong>
              <span>{year}</span>
            </h3>
            <span className="pale">
              {Text.startsFrom}
              <PriceNisDisplay price={minPrice} />
            </span>
          </StyledContentBox>
        </a>
      </Link>
    </StyledPopularModelsSwiperSlide>
  );
}

export default PopularModelsSwiperSlide;
