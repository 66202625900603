import {
  StyledFamilyTypeSection,
  StyledFamilyTypeList,
  StyledHeading,
} from './family-type-section.styled';
import Texts from './family-type-section.text.json';
import FamilyTypeItem from './family-type-item/family-type-item';
import familyTypeItems from '../../../statics/car-family-type';
import { Container } from '../../../components/layouts/layout/layout';

export function FamilyTypeSection() {
  return (
    <StyledFamilyTypeSection>
      <Container>
        <StyledHeading>{Texts.sectionHeading}</StyledHeading>
        <StyledFamilyTypeList>
          {familyTypeItems.map((familyTypeItem) => (
            <FamilyTypeItem key={familyTypeItem.id} {...familyTypeItem} />
          ))}
        </StyledFamilyTypeList>
      </Container>
    </StyledFamilyTypeSection>
  );
}

export default FamilyTypeSection;
