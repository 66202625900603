import PopularModelsSwiperSlide from './popular-models-swiper-slide/popular-models-swiper-slide';
import {
  StyledPopularModelsSwiper,
  StyledSwiper,
} from './popular-models-swiper.styled';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Model } from '@y2/api-clients/price-list';
SwiperCore.use([Pagination, Navigation]);

type Props = {
  models: Model[];
};

export function PopularModelsSwiper({ models }: Props) {
  return (
    <StyledPopularModelsSwiper>
      <StyledSwiper slidesPerView="auto" spaceBetween={6} navigation>
        {models.map((model) => (
          <SwiperSlide key={`popularModelSlide${model.id}`}>
            <PopularModelsSwiperSlide {...model} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </StyledPopularModelsSwiper>
  );
}

export default PopularModelsSwiper;
