import {
  StyledWhyUsSection,
  StyledNarrowContainer,
  StyledWhyUsContent,
  StyledHeading,
} from './why-us-section.styled';
import React from 'react';
import Texts from './why-us-section.text.json';
import WhyUsContentItem from './why-us-content-item/why-us-content-item';
import PriceDisclaimer from '../../price-disclaimer/price-disclaimer';

export function WhyUsSection() {
  return (
    <StyledWhyUsSection>
      <StyledNarrowContainer>
        <StyledHeading>{Texts.sectionHeading}</StyledHeading>
        <StyledWhyUsContent>
          <WhyUsContentItem
            heading={Texts.content.allCars.heading}
            content={Texts.content.allCars.paragraph}
            imgPath="private.svg"
          />
          <WhyUsContentItem
            heading={Texts.content.allPrices.heading}
            content={Texts.content.allPrices.paragraph}
            imgPath="calculator.svg"
          />
          <WhyUsContentItem
            priceDisclaimer={<PriceDisclaimer className="priceDisclaimer" />}
            heading={Texts.content.morePrecise.heading}
            content={Texts.content.morePrecise.paragraph}
            imgPath="accurate.svg"
          />
        </StyledWhyUsContent>
      </StyledNarrowContainer>
    </StyledWhyUsSection>
  );
}

export default WhyUsSection;
