import {
  StyledPopularManufacturers,
  StyledPopularManufacturersList,
} from './popular-manufacturers.styled';
import PopularManufacturersItem from './popular-manufacturers-item/popular-manufacturers-item';
import { fields } from '../../../../services/search/consts';
import { Manufacturer } from '@y2/api-clients/price-list';
import Link from 'next/link';

type Props = {
  isCollapsed: boolean;
  manufacturers: Manufacturer[];
};

export function PopularManufacturers({ isCollapsed, manufacturers }: Props) {
  return (
    <StyledPopularManufacturers isCollapsed={isCollapsed}>
      <StyledPopularManufacturersList isCollapsed={isCollapsed}>
        {manufacturers.map(({ img, title, id }) => (
          <li key={id}>
            <Link href={`/feed?${fields.MANUFACTURER}=${id}`} legacyBehavior>
              <a>
                <PopularManufacturersItem img={img} text={title} />
              </a>
            </Link>
          </li>
        ))}
      </StyledPopularManufacturersList>
    </StyledPopularManufacturers>
  );
}

export default PopularManufacturers;
