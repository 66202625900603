import Text from './last-searches.text.json';
import {
  GrayWrapper,
  Container,
} from '../../../components/layouts/layout/layout';
import { LastSearchesList } from './last-searches-list/last-searches-list';
import styled from 'styled-components';
import { ComponentProps } from 'react';

type Props = {
  className?: string;
  lastSearches: LastSearchesProps['lastSearches'];
  onDeleteByIndex: LastSearchesProps['onDeleteByIndex'];
};

type LastSearchesProps = ComponentProps<typeof LastSearchesList>;

export function LastSearches({
  className,
  lastSearches,
  onDeleteByIndex,
}: Props) {
  return lastSearches.length ? (
    <GrayWrapper as="section" className={className}>
      <Container>
        <LastSearchesBox>
          <Heading>{Text.heading}</Heading>
          <LastSearchesList
            onDeleteByIndex={onDeleteByIndex}
            lastSearches={lastSearches}
          />
        </LastSearchesBox>
      </Container>
    </GrayWrapper>
  ) : null;
}

const LastSearchesBox = styled.div`
  padding: 55px 0 40px;
`;

export const Heading = styled.h2`
  ${({ theme }) => theme.typography.fontWeight.regular}
  ${({ theme }) => theme.typography.fontSize.l}
`;
