import styled from 'styled-components';

export const StyledWhyUsContentItem = styled.li`
  display: flex;

  ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: column-reverse;
    width: 200px;
    height: 230px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    height: 100px;
    width: 100%;
  }

  h3 {
    ${({ theme }) => theme.typography.fontWeight.medium}
    ${({ theme }) => theme.breakpoints.desktop} {
      ${({ theme }) => theme.typography.fontSize.l}
    }

    ${({ theme }) => theme.breakpoints.mobile} {
      ${({ theme }) => theme.typography.fontSize.m}
    }
    display: inline-block;
    vertical-align: middle;
  }
`;

export const StyledHeadingBox = styled.div`
  margin-bottom: 8px;

  .priceDisclaimer {
    margin-right: 6px;
    vertical-align: middle;
  }
`;

export const StyledContentBox = styled.div`
  ${({ theme }) => theme.breakpoints.mobile} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex: 2;
  }
`;

export const StyledImageBox = styled.div`
  align-items: center;
  text-align: center;

  flex: 1;

  img {
    max-height: 100%;
  }
`;
