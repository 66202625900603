import {
  StyledCover,
  MainHeadline,
  SubHeadline,
  DesktopOnly,
  CarSlide,
  StyledContainer,
} from './cover.styled';
import texts from './cover.text.json';
import CoverNav from './cover-nav/cover-nav';
import { Container } from '../layouts/layout/layout';

export default function Cover() {
  return (
    <StyledCover>
      <StyledContainer>
        <MainHeadline>
          <span>{texts.mainHeadline}</span>
          {texts.mainHeadlineAddition}
        </MainHeadline>
        <SubHeadline>
          {texts.subHeadline}
          <DesktopOnly>{texts.subHeadlineDesktopOnly}</DesktopOnly>
          <strong>{texts.subHeadlineAddition}</strong>
        </SubHeadline>
        <CoverNav />
      </StyledContainer>
      <CarSlide>
        <Container />
      </CarSlide>
    </StyledCover>
  );
}
