import styled from 'styled-components';

export const StyledImagBox = styled.div`
  overflow: visible;
  height: 178px;
  width: 100%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.common.isabelline};
  display: flex;
  align-items: center;
`;

export const StyledContentBox = styled.div`
  padding: 12px;
  height: 96px;
  line-height: 1.8;

  strong {
    height: 1.8em;
    display: block;
    ${({ theme }) => theme.typography.fontWeight.medium}
  }

  > span {
    display: block;
    ${({ theme }) => theme.typography.fontSize.xs}

    &.pale {
      color: ${({ theme }) => theme.palette.common.spanishGrey};
    }
  }
`;

export const StyledImage = styled.div`
  transform: translateX(-14px);
`;

export const StyledPopularModelsSwiperSlide = styled.div`
  user-select: none;
  overflow: visible;
  width: 260px;
  height: 275px;
  border-radius: 15px;
  margin: 10px;
  ${({ theme }) => theme.shadows.level[3]}
`;
