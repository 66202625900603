import StyledPopularModelsSwiper from './popular-models-swiper/popular-models-swiper';
import { Container } from '../../../components/layouts/layout/layout';
import Texts from './popular-models-section.text.json';
import { Model } from '@y2/api-clients/price-list';

import {
  StyledPopularModelsSection,
  StyledHeading,
} from './popular-models-section.styled';
import styled from 'styled-components';

type Props = {
  models: Model[];
};

export function PopularModelsSection({ models }: Props) {
  return (
    <StyledPopularModelsSection>
      <OverflowedContainer>
        <StyledHeading>{Texts.sectionHeading}</StyledHeading>
        <StyledPopularModelsSwiper models={models} />
      </OverflowedContainer>
    </StyledPopularModelsSection>
  );
}

const OverflowedContainer = styled(Container)`
  overflow: visible;
`;

export default PopularModelsSection;
