/**
 * This hooks is for popovers, popups, and other floaters that pops above the DOM
 * The hooks make sure that on the open trigger the container will be inside the user viewport
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect } from 'react';
const ESTIMATED_MOBILE_BREAK = 880;

export const usePopupViewportPosition = (
  contentContainerRef: RefObject<any>,
  isOpen: boolean,
  desktopOnly = false,
) => {
  useEffect(() => {
    if (!contentContainerRef.current) {
      return;
    }

    const contentContainer = contentContainerRef.current;
    const shouldSkip =
      // eslint-disable-next-line custom-rules/client-width
      desktopOnly && window.innerWidth < ESTIMATED_MOBILE_BREAK;
    if (isOpen && !shouldSkip) {
      let pixelsToMove = 0;
      const { left, right } = contentContainer.getBoundingClientRect();
      const bodyWidth = document.body.offsetWidth;

      // eslint-disable-next-line custom-rules/no-nested-if-conditions
      if (left < 0) {
        pixelsToMove = -left;
      } else if (right > bodyWidth) {
        pixelsToMove = bodyWidth - right;
      }
      contentContainer.style.transform = `translate(${pixelsToMove}px)`;
    }

    const onDispose = () => {
      contentContainer.style.transform = 'translate(0)';
    };

    return onDispose;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
};
