import { analytics } from '@y2/google-tag-manager';
import { environment } from '../../environments/environment';

export const pushSearchPageEvent = (pageVirtualURL: string, userId?: number) =>
  analytics.pushPageEvent({
    PageType: 'Price list search',
    CatID: 'null',
    SubCatID: 'null',
    PageVirtualURL: pageVirtualURL,
    environment: environment.env,
    UserID: userId?.toString() || '',
    ClientStatus: userId ? 'loggedin' : 'unknown',
  });

export const wizardNavTypeEvent = (label: string) =>
  analytics.pushEvent({
    GA_event_category: 'price list',
    GA_event_action: 'choose process clicked',
    GA_event_label: label,
    GA_event_label_value: 'N/A',
  });

export const pushSearchProcessEvent = (label: string, action: string) => {
  analytics.pushEvent({
    GA_event_category: 'price list',
    GA_event_action: action,
    GA_event_label: label,
    GA_event_label_value: 'N/A',
  });
};

export const pushSearchFinishedEvent = (action: string) => {
  analytics.pushEvent({
    GA_event_category: 'price list',
    GA_event_action: action,
    GA_event_label: 'success',
    GA_event_label_value: 'N/A',
  });
};

export const pushLicensePlateSubmitEvent = (isFound: boolean) => {
  analytics.pushEvent({
    GA_event_category: 'price list',
    GA_event_action: 'specific model process',
    GA_event_label: 'success',
    GA_event_label_value: isFound
      ? 'dmv information - available'
      : 'dmv information - not available',
  });
};
