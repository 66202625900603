import styled from 'styled-components';
import { Container } from '../../../components/layouts/layout/layout';

export const StyledNarrowContainer = styled(Container)`
  width: 870px;
  overflow: initial;
`;

export const StyledWhyUsSection = styled.section`
  margin-top: 100px;
  margin-bottom: 150px;
`;

export const StyledHeading = styled.h2`
  white-space: pre-wrap;
  text-align: center;
  font-size: 42px;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ theme }) => theme.typography.fontSize.xxxl}
  }

  &:after {
    content: '';
    display: block;
    margin: 10px auto;
    width: 50px;
    border-bottom: 4px solid
      ${({ theme }) => theme.palette.primary.safetyOrange};
  }
`;

export const StyledWhyUsContent = styled.ul`
  display: flex;
  justify-content: space-between;
  row-gap: 20px;
  padding: 0 10px;

  ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
