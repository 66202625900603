import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LastSearchesItem } from './last-searches-item/last-searches-item';
import { ComponentProps } from 'react';

type LastSearchesItemProps = ComponentProps<
  typeof LastSearchesItem
>['lastSearch'];

type Props = {
  className?: string;
  lastSearches: LastSearchesItemProps[];
  onDeleteByIndex: (index: number) => void;
};

export function LastSearchesList({
  className,
  lastSearches,
  onDeleteByIndex,
}: Props) {
  return (
    <LastSearchesListBox className={className}>
      <Swiper slidesPerView="auto" spaceBetween={18}>
        {lastSearches.map((lastSearch, index) => (
          <SwiperSlide key={index}>
            <LastSearchesItem
              lastSearch={lastSearch}
              onDelete={() => {
                onDeleteByIndex(index);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </LastSearchesListBox>
  );
}

export const LastSearchesListBox = styled.div`
  padding: 20px 0;

  .swiper-slide {
    width: auto;
  }
`;
