import { fields } from '../../../../services/search/consts';
import * as Icons from '@y2/deprecated/ui/icons';
import styled from 'styled-components';
import Link from 'next/link';
import React from 'react';

type Props = {
  id: number;
  icon: keyof typeof Icons;
  label: string;
};

export function FamilyTypeItem({ id, icon, label }: Props) {
  const Icon = Icons[icon];
  return (
    <StyledFamilyTypeItem>
      <Link href={`/feed?${fields.FAMILY_TYPE}=${id}`} passHref legacyBehavior>
        <Anchor>
          <Icon />
          <h3>{label}</h3>
        </Anchor>
      </Link>
    </StyledFamilyTypeItem>
  );
}

export const StyledFamilyTypeItem = styled.li`
  height: 60px;
  width: 215px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  ${({ theme }) => theme.shadows.level[2]}

  svg {
    margin-left: 12px;
    font-size: 60px;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: calc(50% - 6px);

    svg > g > path {
      fill: none;
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    &:not(:hover) svg > g > path {
      fill: none;
    }
  }
`;

const Anchor = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default FamilyTypeItem;
