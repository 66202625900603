export default [
  {
    label: 'קרוסאובר',
    headingLabel: 'קרוסאוברים',
    icon: 'CrossoverIcon',
    id: 10,
  },
  {
    label: 'קטנים',
    headingLabel: 'רכבים קטנים',
    icon: 'SmallIcon',
    id: 1,
  },
  {
    label: 'ג׳יפים',
    headingLabel: "ג'יפים",
    icon: 'JeepIcon',
    id: 5,
  },
  {
    label: 'משפחתיים',
    headingLabel: 'רכבים משפחתיים',
    icon: 'FamilyIcon',
    id: 2,
  },
  {
    label: 'מנהלים',
    headingLabel: 'רכבי מנהלים',
    icon: 'ManagersIcon',
    id: 3,
  },
  {
    label: 'יוקרה',
    headingLabel: 'רכבי יוקרה',
    icon: 'LaxuryIcon',
    id: 8,
  },
  {
    label: 'ספורט',
    headingLabel: 'רכבי ספורט',
    icon: 'SportIcon',
    id: 4,
  },
  {
    label: 'מיניוואנים',
    headingLabel: 'מיניוואנים',
    icon: 'MinivanIcon',
    id: 9,
  },
  {
    label: 'טנדרים',
    headingLabel: 'טנדרים',
    icon: 'TenderIcon',
    id: 6,
  },
  {
    label: 'מסחריות',
    headingLabel: 'מסחריות',
    icon: 'MishariIcon',
    id: 7,
  },
] as const;
