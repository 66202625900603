import styled from 'styled-components';
import { Container } from '../layouts/layout/layout';
import { getAssetPath } from '../../services/assets/assets';

export const CarSlide = styled.div`
  position: absolute;
  width: 100%;
  height: 330px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.desktop} {
    bottom: -14px;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    bottom: 10px;
  }

  > div {
    height: 100%;
    overflow: visible;
    padding: 0;

    &:after {
      content: '';
      display: block;
      background: url('${getAssetPath('cover_car_v2.png')}') no-repeat bottom
        center;
      background-size: 100%;
      height: 100%;

      ${({ theme }) => theme.breakpoints.desktop} {
        width: 500px;
        margin-right: auto;
      }

      ${({ theme }) => theme.breakpoints.mobile} {
        width: 350px;
        max-width: 100%;
        margin: auto;
      }
    }
  }
`;

export const skyColor = '#E3F5F6';
const roadColor = '#C6DEE0';

export const StyledCover = styled.header`
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.mobile} {
    text-align: center;
    box-sizing: content-box;
    height: 580px;
    padding-bottom: 40px;
    background-color: ${roadColor};

    // Mobile clouds remover
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 250px;
      background-color: ${skyColor};
    }
  }

  // The background part
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 100%;
    display: block;
    background: ${skyColor}
      url('${getAssetPath('cover_bg_buildings_clouds&road.svg')}') no-repeat
      100% 100%;

    ${({ theme }) => theme.breakpoints.desktop} {
      height: 460px;
    }

    ${({ theme }) => theme.breakpoints.mobile} {
      background-position: 30% bottom;
      height: 550px;
      background-size: auto 52%;
    }
  }
`;

export const MainHeadline = styled.h1`
  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 3.685rem;
  }

  > span {
    color: ${({ theme }) => theme.palette.primary.safetyOrange};
  }
`;

export const SubHeadline = styled.h3`
  ${({ theme }) => theme.typography.fontWeight.light};

  ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 530px;
    ${({ theme }) => theme.typography.fontSize.xl}
    line-height: 34px;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ theme }) => theme.typography.fontSize.s}
    line-height: 26px;
  }

  > strong {
    ${({ theme }) => theme.breakpoints.desktop} {
      ${({ theme }) => theme.typography.fontWeight.regular}
    }

    ${({ theme }) => theme.breakpoints.mobile} {
      display: block;
      font-weight: inherit;
    }
  }
`;

export const DesktopOnly = styled.span`
  ${({ theme }) => theme.breakpoints.mobile} {
    display: none;
  }
`;

export const StyledContainer = styled(Container)`
  position: relative;
  overflow: visible;
  z-index: 5;

  ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 100px;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ theme }) => theme.typography.fontSize.xxxl};
    padding-top: 40px;
  }
`;
