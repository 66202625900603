import { priceListClient } from '@y2/api-clients/price-list';

const client = priceListClient();

export const getUserLastSearches = async () => {
  const {
    data: { data },
  } = await client.getLastSearches();
  return data;
};

export const { deleteLastSearch, saveLastSearches } = client;
