import styled from 'styled-components';
import { CloseIcon } from '@y2/mango/components/icons';

type Props = { onClick: () => void };

export const CloseButton = ({ onClick }: Props) => (
  <StyledCloseButton onClick={onClick}>
    <CloseIcon />
  </StyledCloseButton>
);

export const StyledCloseButton = styled.button`
  position: absolute;
  left: 12px;
  top: 12px;
  ${({ theme }) => theme.typography.fontSize.xxl};
  ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;
