import {
  StyledWhyUsContentItem,
  StyledContentBox,
  StyledImageBox,
  StyledHeadingBox,
} from './why-us-content-item.styled';
import { getAssetPath } from '../../../../services/assets/assets';
import React from 'react';

export type WhyUsContentItemProps = {
  heading: string;
  content: string;
  imgPath: string;
  priceDisclaimer?: React.ReactNode;
};

function WhyUsContentItem(props: WhyUsContentItemProps) {
  const { priceDisclaimer, ...styledWhyUsContentItemProps } = props;
  const { heading, content, imgPath } = styledWhyUsContentItemProps;
  return (
    <StyledWhyUsContentItem>
      <StyledContentBox>
        <StyledHeadingBox>
          <h3>{heading}</h3>
          {priceDisclaimer}
        </StyledHeadingBox>

        <p>{content}</p>
      </StyledContentBox>
      <StyledImageBox>
        <img src={getAssetPath(imgPath)} alt="" />
      </StyledImageBox>
    </StyledWhyUsContentItem>
  );
}
export default WhyUsContentItem;
