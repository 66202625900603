import styled, { css } from 'styled-components';

export interface PopularManufacturersProps {
  isCollapsed: boolean;
}

export const StyledPopularManufacturers = styled.div<PopularManufacturersProps>`
  height: ${({ isCollapsed }) => (isCollapsed ? '92px' : 'auto')};
  overflow: hidden;
  margin-top: 20px;
  ${(props) =>
    props.isCollapsed
      ? css`
          width: calc(100% + 15px);
          overflow: hidden;
        `
      : css`
          width: 100%;
        `}
`;

export const StyledPopularManufacturersList = styled.ul<PopularManufacturersProps>`
  display: flex;

  ${({ theme }) => theme.breakpoints.desktop} {
    gap: 40px 42px;
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    width: fit-content;
    justify-content: space-between;
    row-gap: 22px;
    flex-wrap: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'wrap')};
  }
`;
