import styled from 'styled-components';
import { TooltipProps } from '../../../components/tooltip/tooltip';
import { InfoIcon } from '@y2/mango/components/icons';

type Props = TooltipProps['TooltipButton'];

export const InfoButton: Props = ({ onClick, onMouseEnter, onMouseLeave }) => (
  <StyledInfoButton
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <InfoIcon />
  </StyledInfoButton>
);

export const StyledInfoButton = styled.button`
  ${({ theme }) => theme.typography.fontSize.xxl}
  display: block;
  svg {
    display: block;
  }
`;
