import { CollapsibleProps } from '../popular-manufacturers-section.styled';
import Texts from '../popular-manufacturers-section.text.json';
import { StyledShowMoreButton } from './show-more-button.styled';
import { CloseIcon } from '@y2/deprecated/ui/icons';
import React from 'react';

interface ShowMoreButton extends CollapsibleProps {
  onClick: () => void;
}

export function ShowMoreButton(props: ShowMoreButton) {
  return (
    <StyledShowMoreButton
      data-testid="click-btn"
      onClick={props.onClick}
      isCollapsed={props.isCollapsed}
    >
      <span>
        {props.isCollapsed ? Texts.showMoreButton : Texts.showLessButton}
      </span>
      <CloseIcon />
    </StyledShowMoreButton>
  );
}

export default ShowMoreButton;
