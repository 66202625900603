import React from 'react';
import StyledCoverNavItem from './cover-nav-item.styled';
import Link from 'next/link';
import { wizardNavTypeEvent } from '../../../../services/events/search';

type CoverNavItemProps = {
  children?: React.ReactNode;
  href: string;
  text: string;
  eventLabel: string;
};

export function CoverNavItem(props: CoverNavItemProps) {
  return (
    <Link href={props.href} passHref legacyBehavior>
      <StyledCoverNavItem
        onClick={() => {
          wizardNavTypeEvent(props.eventLabel);
        }}
      >
        {props.children}
        <span>{props.text}</span>
      </StyledCoverNavItem>
    </Link>
  );
}

export default CoverNavItem;
