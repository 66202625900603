import styled, { css } from 'styled-components';
import { CollapsibleProps } from '../popular-manufacturers-section.styled';

export const StyledShowMoreButton = styled.button<CollapsibleProps>`
  display: block;
  padding: 6px;
  color: ${({ theme }) => theme.palette.primary.safetyOrange};
  margin-right: auto;

  svg {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    ${({ isCollapsed }) =>
      !isCollapsed &&
      css`
        position: absolute;
        left: -6px;
      `}

    span {
      display: ${({ isCollapsed }) => (isCollapsed ? 'initial' : 'none')};
    }

    svg {
      ${({ isCollapsed }) => !isCollapsed && 'display: initial;'}
      color: ${({ theme }) => theme.palette.common.jet};
      font-size: 40px;
    }
  }
`;
